<template>
  <div class="product-page">
    <v-row no-gutters>
      <v-col cols="12" class="page-header">
        <span class="page-title">{{ $t('Orders') }}</span>
        <!-- <v-btn color="primary" @click.stop="drawer = !drawer">More filters </v-btn> -->
      </v-col>
      <v-col cols="12" class="d-flex">
        <v-btn
          v-if="utilities.checkPermisson(`export_orders`, user)"
          outlined
          color="primary"
          @click="modalExport = true"
          ><i class="fas fa-file-export mr-1"></i>{{ $t('Export') }}
        </v-btn>
        <v-spacer></v-spacer>
        <!-- <v-btn outlined class="ml-2" color="primary"
          ><i class="fas fa-download"></i>{{ $t('Import tracking number') }}
        </v-btn> -->
      </v-col>
    </v-row>
    <Orders />
    <modal width="600px" model="modalExport" title="Export orders" :callback="submitExportCSV">
      <template slot="content">
        <v-row>
          <v-col>
            <div>
              <h3>Store</h3>
              <v-select
                v-model="selectedStores"
                :items="stores"
                multiple
                chips
                item-value="id"
                item-text="storeDetail.defaultSystemDomain"
                background-color="#e6e6e6"
                label="Store"
              >
                <!-- <template v-slot:item="data">
                  <span v-if="data.item.storeDetail">{{ data.item.storeDetail.name }}</span>
                </template>
                <template v-slot:selection="data">
                  <span v-if="data.item.storeDetail">{{ data.item.storeDetail.name }}</span>
                </template> -->
              </v-select>
            </div>
            <div>
              <h3>Export</h3>
              <v-radio-group v-model="radioGroup">
                <!-- <v-radio value="current-page" label="Current page"></v-radio>
                <v-radio value="order-by-date" label="Order by date"></v-radio> -->
                <v-radio @click="selectAll" value="all-products" label="All orders"></v-radio>
                <!-- <v-radio value="selected-0-order" disabled label="Selected 0 order"></v-radio> -->
                <v-radio @click="selectCurent" value="curent-search" label="Current search"></v-radio>
              </v-radio-group>
            </div>
          </v-col>
          <!-- <v-col>
            <div>
              <h3>Export as:</h3>
              <v-radio-group>
                <v-radio value="csv" label="CSV for Excel, Numbers, or other spreadsheet programs"></v-radio>
                <v-radio value="order-by-date" label="Plain csv file"></v-radio>
              </v-radio-group>
            </div>
            <div>
              <h3>Export template:</h3>
              <v-radio-group>
                <v-radio value="" label="Orders"></v-radio>
                <v-radio value="" label="Transaction histories"></v-radio>
                <v-radio value="" label="Customize export fields"></v-radio>
              </v-radio-group>
            </div>
          </v-col> -->
        </v-row>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import utilities from '@/helpers/utilities';
import { storeService } from '@/apis/store.s';
import Orders from './components/Orders';
import { orderApi } from '@/apis/order';
export default {
  components: { Orders },
  data() {
    return {
      stores: null,
      selectedStores: [],
      utilities,
      radioGroup: '',
      searchOrder: '',
      params: {
        page: this.page,
        limit: this.limit,
      },
      checkCurentSearch: true,
      value: [],
      modalExport: false,
      items: ['1', '2', '3'],
    };
  },
  watch: {
    searchOrder() {
      console.log(this.searchOrder);
    },
  },
  computed: {
    ...mapGetters(['user']),
  },
  mounted() {
    this.getStores();
  },
  methods: {
    async getStores() {
      try {
        let response = await storeService.list({
          limit: 100,
        });
        this.stores = response.data.filter(item => item !== null);
        this.stores = this.stores.map(function(store) {
          return {
            ...store,
            storeName: store.storeDetail.name,
          };
        });
        // console.log('stores', this.stores);
      } catch (error) {
        console.log(error);
      }
    },
    selectAll() {
      this.params = {};
    },
    selectCurent() {
      // console.log(this.searchOrder);
      // this.params.curentSearch = this.$router.app._route.query._query;
      if (this.searchOrder != '') {
        this.params.search = this.searchOrder;
      }
      if (this.$route.query._query) {
        let queries = JSON.parse(this.$route.query._query);
        queries.forEach(q => {
          if (q.compare) {
            this.params[q.field] = q.value;
            this.params[q.fieldCompare] = q.compare;
          } else {
            this.params[q.field] = Array.isArray(q.value) ? q.value.join(',') : q.value;
          }
        });
      }
    },
    downloadFile(fileName, urlData) {
      var aLink = document.createElement('a');
      aLink.download = fileName;
      aLink.href = urlData;
      var event = new MouseEvent('click');
      aLink.dispatchEvent(event);
    },
    async submitExportCSV() {
      if (this.selectedStores) {
        this.params.storeIds = this.selectedStores.join(',');
      }
      let data = await orderApi.getExport(this.params);
      this.downloadFile('export.csv', 'data:text/csv;charset=UTF-8,' + encodeURIComponent(data.data));
      this.modalExport = false;
      this.radioGroup = '';
    },
  },
};
</script>
<style scoped>
.v-label {
  font-size: 14px !important;
}
</style>
