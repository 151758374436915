export default [
  {
    label: 'Status',
    field: 'status',
    type: {
      name: 'checkbox',
    },
    model: '',
    items: [
      { _id: 'open', title: 'Open' },
      { _id: 'archived', title: 'Archived' },
      { _id: 'cancelled', title: 'Cancelled' },
    ],
    values: [
      {
        name: 'Open',
        value: 'open',
      },
      {
        name: 'Archived',
        value: 'archived',
      },
      {
        name: 'Cancelled',
        value: 'cancelled',
      },
    ],
    scoped: 'order',
  },
  {
    label: 'Capture Status',
    field: 'paymentStatus',
    type: {
      name: 'checkbox',
    },
    model: '',
    items: [
      { _id: 'COMPLETED', title: 'Completed' },
      { _id: 'REFUNDED', title: 'Refunded' },
      { _id: 'PARTIAL_REFUND', title: 'Partial Refund' },
      { _id: 'DISPUTED', title: 'disputed' },
    ],
    values: [
      {
        name: 'Completed',
        value: 'COMPLETED',
      },
      {
        name: 'Refunded',
        value: 'REFUNDED',
      },
      {
        name: 'Partial Refund',
        value: 'PARTIAL_REFUND',
      },
    ],
    scoped: 'order',
  },
  {
    label: 'Fulfillment Status',
    field: 'fulfillmentStatus',
    type: {
      name: 'checkbox',
    },
    model: '',
    items: [
      { _id: 'unfulfilled', title: 'Unfulfilled' },
      { _id: 'partially_unfulfilled', title: 'Partially Unfulfilled' },
      { _id: 'fulfilled', title: 'Fulfilled' },
      { _id: 'processing', title: 'Processing' },
      { _id: 'partially_processing', title: 'Partially Processing' },
    ],
    values: [
      {
        name: 'Unfulfilled',
        value: 'unfulfilled',
      },
      {
        name: 'Partially Unfulfilled',
        value: 'partially_unfulfilled',
      },
      {
        name: 'Fulfilled',
        value: 'fulfilled',
      },
      {
        name: 'Processing',
        value: 'processing',
      },
      {
        name: 'Partially Processing',
        value: 'partially_processing',
      },
    ],
    scoped: 'order',
  },
  {
    label: 'Order Number',
    field: 'orderName',
    type: {
      name: 'order-name',
    },
    // kind: 'Obj',
    // model: [],
    model0: '',
    model1: '',
  },
  {
    label: 'Order Date From To',
    field: 'orderDate',
    type: {
      name: 'date',
    },
    kind: 'Obj',
    model: '',
    scoped: 'order',
  },
  {
    label: 'Fulfillment Date From To',
    field: 'fulfillmentDate',
    kind: 'Obj',
    type: {
      name: 'date',
    },
    model: '',
    scoped: 'order',
  },
  {
    label: 'Order Country',
    field: 'orderCountry',
    type: {
      name: 'country',
      text: 'name',
      value: 'code',
      // name: 'country',
    },
    model: '',
    scoped: 'order',
  },
  {
    label: 'Product Type',
    field: 'productType',
    fieldCompare: 'productTypeMode',
    type: {
      name: 'compare',
      text: 'title',
      value: '_id',
    },
    // compare để lưu giá trị ở radio
    compare: '',
    // model lưu text field bên dưới
    model: '',
    values: [
      {
        name: 'Contains',
        value: 'contains',
      },
      // {
      //   name: 'Doesnt Contains',
      //   value: 'doesnt-contains',
      // },
    ],
    scoped: 'order',
  },
  {
    label: 'Product Vendor',
    field: 'productVendor',
    fieldCompare: 'productVendorMode',
    type: {
      name: 'compare',
      text: 'title',
      value: '_id',
    },
    compare: '',
    model: '',
    values: [
      {
        name: 'Contains',
        value: 'contains',
      },
      // {
      //   name: 'Doesnt Contains',
      //   value: 'doesnt-contains',
      // },
    ],
    scoped: 'order',
  },
  {
    label: 'Product Sku',
    field: 'productSku',
    fieldCompare: 'productSkuMode',
    type: {
      name: 'compare',
      text: 'title',
      value: '_id',
    },
    compare: '',
    model: '',
    values: [
      {
        name: 'Contains',
        value: 'contains',
      },
      // {
      //   name: 'Doesnt Contains',
      //   value: 'doesnt-contains',
      // },
    ],
    scoped: 'order',
  },
  {
    label: 'Customer Email',
    field: 'customerEmail',
    type: {
      name: 'email',
      mutiple: true,
      text: 'title',
      value: '_id',
    },
    model: '',
    scoped: 'order',
  },
];
